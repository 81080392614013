<template>
	<div id="studentList">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>基础配置</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/statement' }">报表中心</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学生数据报表</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item u-f-jsb" style="padding-right: 15px;border-bottom: 1px solid #EEEEEE;">
					<div class="title">共{{ total }}条数据</div>
					<el-button size="mini" type="primary" @click="exportStu">导出</el-button>
				</div>
				<div style="padding:0 15px;">
					<el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%">
						<!-- <el-table-column type="selection" width="55"></el-table-column> -->
						<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
						<el-table-column prop="class_name" label="班级" align="center"></el-table-column>
						<el-table-column prop="realname" label="姓名" align="center"></el-table-column>
						<el-table-column prop="class_num" label="学号" align="center"></el-table-column>
						<el-table-column prop="student_num" label="学籍号" align="center"></el-table-column>
						<el-table-column prop="sex" label="性别" align="center">
							<template slot-scope="scope">
								<div>{{ scope.row.sex == 1 ? '男' : '女' }}</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="stunature_status"
							label="学籍性质">
							<template slot-scope="scope">
								<div>{{ scope.row.stunature_status == 1 ? '本校' : '挂学籍' }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="状态" align="center">
							<template slot-scope="scope">
								<div>{{ scope.row.status == 1 ? '在校' : '离校' }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center"></el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="13"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import Progress from 'easy-circular-progress';
import index from '@/components/common/index.vue';
export default {
	components: {
		Progress,
		index
	},
	props: {},
	data() {
		return {
			page: 1,
			total: 0,
			List: []
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		// 性别筛选
		filtersex(row, column) {
			return row.sex;
		},
		// 学科筛选
		filtersubject(row, column) {
			return row.subject;
		},
		// 班级筛选
		filterclasses(row, column) {
			return row.classes;
		},
		// 性质筛选
		filternature(row, column) {
			return row.nature;
		},
		// 职称筛选
		filterrank(row, column) {
			return row.rank;
		},
		// 工资筛选
		filtersalary(row, column) {
			return row.salary;
		},
		// 身份筛选
		filteridentity(row, column) {
			return row.identity;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.initData();
			console.log(`当前页: ${val}`);
		},
		initData() {
			this.$api.student
				.studentList({
					year_id: window.localStorage.getItem('year_id'),
					semester_id: window.localStorage.getItem('semester_id'),
					type: 1,
					page: this.page,
					limit: 13
				})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.total = res.data.data.total;
						this.List = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		exportStu(){
			this.$api.student.exportStu({}).then(res=>{
				if(res.data.code==1){
					location.href = res.data.data.url;
				}
			})
		}
	}
};
</script>

<style lang="scss">
#studentList {
	.head {
		background-color: #ffffff;
		padding: 8px 20px;
		margin-top: 15px;
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.main-item {
		background: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
			color: #4e4e4e;
			border-bottom: 1px solid #fafafb;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 15px;
		height: 85vh;
		overflow: hidden;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.vue-circular-progress .circle__progress {
		stroke-linecap: square;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px;
		.el-radio {
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.el-dialog__footer {
		border-top: 1px solid #eeeeee;
		text-align: center;
	}
	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
